<template>
  <b-card>
    <b-row>
      <b-col md="4" class="pb-1">
        <b-button
          class="w-100"
          variant="outline-primary"
          :to="{ name: 'organization-confirmation-planning-add' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          {{ windowWidth <= 1520 ? $t('message.Add_Conf_Plan') :  $t('message.Add_Confirmation_Planning') }}
        </b-button>
      </b-col>

      <b-col md="4"  class="pb-1">
        <b-button
          class="w-100"
          variant="outline-info"
          @click="isEventsUploadSidebarActive = true"
        >
          <feather-icon
            icon="UploadIcon"
            class="mr-50"
          />
          {{ windowWidth <= 1520 ? $t('bulkEvUpload') :  $t('bulkEventsUpload') }}
        </b-button>
      </b-col>

      <b-col md="4">
        <b-form-group class="w-100">
          <b-form-input
            v-model="searchQuery"
            type="search"
            class="d-inline-block"
            :placeholder="$t('message.search')"
          />
        </b-form-group>
      </b-col>

      <b-col>
        <filters 
          :roleFilterShow="true"
          :processFilterShow="true"
          @changeFilter="updateFilter"
        />
      </b-col>

      <b-col cols="12">
        <b-table
          ref="refConfirmationPlanningTable"
          :items="confirmationPlannings"
          hover
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty=""
          :empty-text="isLoading ? $t('message.loading') : $t('message.no_records')"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(roleName.name)="data">
            {{ data.item.roleName[0].name }}
          </template>

          <template #cell(processName.name)="data">
            {{ data.item.processName[0].name }}
          </template>

          <template #cell(actions)="data">
            <div class="flex flex-column">
              <EditButton @clicked="router.push({name: 'organization-confirmation-planning-edit', params: { id: data.item._id }})"/>
              <DeleteButton v-b-modal="`modal ${data.item._id}`"/>
              <b-modal
                :id="`modal ${data.item._id}`"
                centered
                no-close-on-backdrop
                modal-class="modal-danger"
                ok-variant="danger"
                cancel-variant="outline-secondary"
                :title="$t('message.confirm_action')"
                :ok-title="$t('message.delete')"
                :cancel-title="$t('message.cancel')"
                @ok="deleteConfirmationPlanning(data.item._id)"
              >
                {{ $t('message.confirm_delete_confirmation_planning') }}
              </b-modal>
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
        class="d-flex justify-content-between flex-wrap"
      >
        <div class="mb-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t('message.per_page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="perPageOptions"
              class="w-50"
            />
          </b-form-group>
        </div>

        <div class="mb-1">
          <span class="text-muted">{{ showingMessage }}</span>
        </div>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalConfirmationPlannings"
          :per-page="perPage"
          first-number=""
          last-number=""
          prev-class="prev-item"
          next-class="next-item"
        />
      </b-col>
    </b-row>
    
    <!-- Sidebar Overlay: Bulk Events Upload -->
    <div
      class="body-content-overlay"
      :class="{'show': isCalendarOverlaySidebarActive}"
      @click="isCalendarOverlaySidebarActive = false"
    />
    <events-upload
      v-model="isEventsUploadSidebarActive"
    />
  </b-card>
</template>

<script>
import { BPagination, BTable, BModal, VBModal } from 'bootstrap-vue'
import { onMounted, ref, onBeforeUnmount } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import useConfirmationPlanningList from './useConfirmationPlanningList'
import Filters from "@/views/organization/Filters.vue";
import EventsUpload from '../events-upload/EventsUpload.vue'

export default {
  components: {
    BTable,
    BPagination,
    EditButton,
    DeleteButton,
    Filters,
    BModal,
    EventsUpload,
  },
  directives: {
    'b-modal': VBModal,
  },
  setup() {
    const { router } = useRouter()
    const {
      fetchConfirmationPlannings,
      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      totalConfirmationPlannings,
      showingMessage,
      searchQuery,
      sortBy,
      isSortDirDesc,
      confirmationPlannings,
      deleteConfirmationPlanning,
      isLoading,
      updateFilter,
    } = useConfirmationPlanningList()

    const isEventsUploadSidebarActive = ref(false)
    const isCalendarOverlaySidebarActive = ref(false)
    const windowWidth = ref(window.innerWidth)

    const updateWindowWidth = () => {
      windowWidth.value = window.innerWidth;
    }

    onMounted(() => {
      fetchConfirmationPlannings()
      window.addEventListener('resize', updateWindowWidth)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateWindowWidth);
    })

    return {
      fetchConfirmationPlannings,
      confirmationPlannings,
      showingMessage,
      router,
      tableColumns,
      searchQuery,
      sortBy,
      isSortDirDesc,
      deleteConfirmationPlanning,
      totalConfirmationPlannings,
      currentPage,
      perPageOptions,
      perPage,
      isLoading,
      updateFilter,
      isEventsUploadSidebarActive,
      isCalendarOverlaySidebarActive,
      windowWidth
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.process-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.form-group {
  label {
    font-size: 0.85rem;
    font-weight: 400;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
